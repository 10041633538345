<template>
  <div
    class="h-[calc(100dvh)] flex flex-col lg:flex-row lg:gap-16 lg:items-center lg:justify-center"
  >
    <CommonFullLogo />
    <div class="lg:w-128">
      <slot />
    </div>
  </div>
</template>
